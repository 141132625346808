import "./bootstrap";

import { createInertiaApp } from "@inertiajs/vue3";
import { resolvePageComponent } from "laravel-vite-plugin/inertia-helpers";
import { createSSRApp, h } from "vue";

// Pinia
import { createPinia } from "pinia";
// Ziggy with locale
import { ZiggyVue } from "./plugins/ziggy";
import { Ziggy } from "./ziggy";

// Dayjs
import dayjs from "./plugins/dayjs";

// Translations
import { createI18n } from "vue-i18n";
import localeMessages from "./vue-i18n-locales.generated";

// Flare
import { flare } from "@flareapp/flare-client";
import { flareVue } from "@flareapp/flare-vue";
// Formkit
import formKitTheme from "@/formkitTheme";
import { createAutoAnimatePlugin } from "@formkit/addons";
import { nl } from "@formkit/i18n";
import { generateClasses } from "@formkit/themes";
import { defaultConfig as FKdc, plugin as FKp } from "@formkit/vue";

import Notifications from "notiwind";

const pinia = createPinia();

if (import.meta.env.PROD) {
    flare.light();
}

const numberFormats = {
    nl: {
        currency: {
            style: "currency",
            currency: "EUR",
        },
        percent: {
            style: "percent",
            useGrouping: true,
        },
    },
};

createInertiaApp({
    progress: { color: "#1B4B43", delay: 500 },
    resolve: (name) => resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob("./Pages/**/*.vue")),
    setup({ el, App, props, plugin }) {
        const currentLocale = props.initialPage.props.locale;

        const i18n = createI18n({
            numberFormats,
            legacy: false,
            locale: currentLocale,
            fallbackLocale: "en",
            messages: localeMessages,
        });

        return createSSRApp({ render: () => h(App, props) })
            .use(plugin)
            .use(pinia)
            .use(flareVue)
            .use(Notifications)
            .use(ZiggyVue, { ...Ziggy, locale: currentLocale })
            .use(dayjs, { locale: currentLocale })
            .use(i18n)
            .use(
                FKp,
                FKdc({
                    config: {
                        classes: generateClasses(formKitTheme),
                    },
                    plugins: [createAutoAnimatePlugin({ duration: 150 })],
                    locales: { nl },
                    locale: "nl",
                    globalInjection: true,
                }),
            )
            .mount(el);
    },
});
